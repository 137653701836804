import { IGNORE_JOB_ID_LIST } from "@/helpers/constants";
import { APIError } from "errors";
import _ from "lodash";
import api from "../api";
import { HTTP_STATUS_CODES } from "@/constants";

const INITIAL_STATE = {
  searches: undefined,
  search: undefined,
  searchesSubmissions: undefined,
  searchesCalls: undefined,
  allSearches: undefined,
  searchesSubmissionsByDate: [],
  searchesCallsByDate: [],
  error: undefined,
  loading: false
};

// ----------> MODEL <----------

export const searches = {
  state: INITIAL_STATE,
  reducers: {
    setSearches(state, searches) {
      return { ...state, searches };
    },

    setSearchesSubmissions(state, searchesSubmissions) {
      return { ...state, searchesSubmissions };
    },

    setSearchesCalls(state, searchesCalls) {
      return { ...state, searchesCalls };
    },

    setAllSearches(state, allSearches) {
      return { ...state, allSearches };
    },

    setSearchesSubmissionsByDate(state, searchesSubmissionsByDate) {
      return { ...state, searchesSubmissionsByDate };
    },

    setSearchesCallsByDate(state, searchesCallsByDate) {
      return { ...state, searchesCallsByDate };
    },

    setLoading(state, loading) {
      return { ...state, loading };
    },

    setError(state, error) {
      return { ...state, error };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    fetchActiveSearches: async (payload) => {
      try {
        dispatch.searches.setLoading(true);

        const { data: searches } = await api.post("/searches", payload);

        const searchesData = searches?.data?.filter(
          (search) => !_.includes(IGNORE_JOB_ID_LIST, search.id)
        );

        dispatch.searches.setSearches(searchesData);

        const jobIds = searchesData?.map((search) => search.id);

        const { data: searchesSubmissions } = await api.post("/searchesSubmissions", {
          jobIds
        });
        const searchesCalltimeURI =
          payload === "refresh" ? "/refreshSearchesCalltime" : "/searchesCalltime";
        const { data: searchesCalltime } = await api.post(searchesCalltimeURI, {
          jobIds
          // user: rootState.user.user.id,
        });

        // TODO: USE API once we can call for specific job ids
        const callsBySearch = searchesCalltime?.data?.reduce((acc, curr) => {
          const jobIds = curr.jobOrders?.data?.map((j) => j.id);

          jobIds.forEach((jobId) => {
            if (acc[jobId]) {
              acc[jobId] = [...acc[jobId], curr];
            } else {
              acc[jobId] = [curr];
            }
          });

          return acc;
        }, {});

        dispatch.searches.setSearchesCalls(callsBySearch);
        // dispatch.searches.setSearchesCalls([]);

        const submissionsBySearch = _.groupBy(searchesSubmissions.data, (submission) => {
          return submission.jobOrder.id;
        });

        dispatch.searches.setSearchesSubmissions(submissionsBySearch);
      } catch (error) {
        // TODO: is this the best way to handle this???
        // set active submissions to empty array so api is not
        // called over and over due to undefined in state
        dispatch.searches.setSearches([]);
        dispatch.searches.setSearchesCalls([]);
        dispatch.searches.setSearchesSubmissions([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.searches.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.searches.setError(error);
        }

        throw error;
      } finally {
        dispatch.searches.setLoading(false);
      }
    },
    fetchAllSearches: async (payload) => {
      try {
        dispatch.searches.setLoading(true);

        const { data: withTestSearches } = await api.post("/allSearches", payload);

        if (withTestSearches?.errorCode > HTTP_STATUS_CODES.OK) return;

        const allSearches = withTestSearches?.filter(
          (search) => !_.includes(IGNORE_JOB_ID_LIST, search.id)
        );

        dispatch.searches.setAllSearches(allSearches);

        const jobIds = allSearches?.map((search) => search.id);

        const { data: searchesSubmissionsByDate } = await api.post("/searchesSubmissionsByDate", {
          ...payload,
          jobIds
        });

        // // TODO: Make work once BH support helps get notes with minutesSpent
        // const { data: searchesCalltimeByDate } = await api.post(
        //   `/searchesCalltimeByDate`,
        //   {
        //     ...payload,
        //     jobIds,
        //     user: rootState.user.user.id,
        //   }
        // );

        // const callsBySearchByDate = _.groupBy(
        //   searchesCalltimeByDate.data,
        //   (calltimeNote) => calltimeNote.jobOrder.id
        // );

        // dispatch.searches.setSearchesCallsByDate(callsBySearchByDate);

        const submissionsBySearchByDate = _.groupBy(
          searchesSubmissionsByDate.data,
          (submission) => {
            return submission.jobOrder.id;
          }
        );

        dispatch.searches.setSearchesSubmissionsByDate(submissionsBySearchByDate);
      } catch (error) {
        // TODO: is this the best way to handle this???
        // set active submissions to empty array so api is not
        // called over and over due to undefined in state
        dispatch.searches.setAllSearches([]);
        dispatch.searches.setSearchesCallsByDate([]);
        dispatch.searches.setSearchesSubmissionsByDate([]);

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.searches.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        } else {
          dispatch.searches.setError(error);
        }

        throw error;
      } finally {
        dispatch.searches.setLoading(false);
      }
    }
  })
};
