import store from "@/store";
import { LOCAL_STORAGE_KEYS } from "@/constants";
import { authService } from "@/services";

// Setting waiting time in 0 we ensure that it runs at the end of the current tasks in event loop.
const WAIT_TIME_TO_REFRESH_IN_MILLS = 0;

/**
 * Unauthorized Interceptor.
 *
 * @param {Axios Client} client
 * @param {Request Counter Interceptor} hasActiveRequests
 */
export const unauthorizedInterceptor = (client, hasActiveRequests) => {
  console.log("401! should check permissions!");

  const refresh_token = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

  const userStore = store.getState().user;

  const getTokensFromRefreshResponse = ({ data }) => {
    const { token: access_token, refresh_token } = data.data;

    if (!data?.success || !access_token || !refresh_token) {
      return;
    }

    client.defaults.headers.common["Authorization"] = access_token;

    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, access_token);
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
  };

  setTimeout(async () => {
    // if has some request in progress interrupt the refresh process.
    if (!hasActiveRequests()) {
      // do refresh request.
      authService
        .refresh(userStore.username, refresh_token)
        .then(getTokensFromRefreshResponse)
        // reload the page after the refresh because it's currently the simplest way to repopulate the Redux store.
        .then(() => window.location.reload());
    }
  }, WAIT_TIME_TO_REFRESH_IN_MILLS);
};
