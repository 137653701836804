// @TODO [salva@18-12-2024] [#VA2-124] add proptypes.
/* eslint-disable react/prop-types */
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "@/styles/config";

const field = [
  "clientContactFirstName",
  "clientContactLastName",
  "clientCorporationName",
  "jobOrderCustomObjectInstance1Float3",
  "jobOrderCustomObjectInstance1Text1"
];
// @TODO [salva@18-12-2024] [#VA2-125] normalize styles with the other tables in the app.
const useStyles = makeStyles(() => ({
  table: {
    backgroundColor: "white",
    borderCollapse: "collapse"
  },
  headerRow: {
    boxShadow: "0px 10px 15px -14px grey",
    "& .MuiTableCell-root": {
      borderTop: `2px solid ${colors.gray1}`,
      padding: "10px 10px",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: colors.gray2
    }
  },
  tableBody: {
    "& .MuiTableRow-root": {
      "&:nth-of-type(odd)": {
        backgroundColor: colors.gray1
      }
    },
    "& .MuiTableCell-root": {
      padding: "10px 16px",
      color: colors.textDark,
      fontSize: "0.875rem",
      borderBottom: `1px solid ${colors.gray3}`
    }
  }
}));

const ContractsTable = ({ contracts }) => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead className={classes.headerRow}>
          <TableRow>
            {field.map((field, index) => (
              <TableCell key={index}>{field}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {contracts.map((row, index) => (
            <TableRow key={`${index}-${row.id}`}>
              {field.map((field, index) => (
                <TableCell key={index} component="th" scope="row">
                  {row[field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export { ContractsTable };
