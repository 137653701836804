const handleExport = (data) => {
  if (!data?.[0]) return;
  // 1. convert to CSV
  const headers = Object.keys(data[0]); // Asume every object has the same key.
  const rows = data.map((row) => headers.map((header) => `"${row[header]}"`).join(","));

  const csvContent = [headers.join(","), ...rows].join("\n");

  // 2. create blob and link to download.
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  return url;
};

export const UTILS = { handleExport };
