import { client } from "@/api/fetchCreator/apiClient";

const refresh = (username, refresh_token) => {
  return client.post("/refresh", {
    username,
    refresh_token
  });
};

export const authService = { refresh };
