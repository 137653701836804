import { setupRequestCounterInterceptor, setupAuthInterceptor } from "./interceptors";

/**
 * Setup Interceptors.
 *
 */
export const setup = (client) => {
  // Request Counter Interceptor.
  const { hasActiveRequests } = setupRequestCounterInterceptor(client);

  // Auth Interceptor.
  setupAuthInterceptor(client, hasActiveRequests);
};
