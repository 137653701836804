/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { TextField, Button, Box, FormControl } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import ErrorMessage from "./ErrorMessage";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    minWidth: "100%"
  }
}));

export default function LoginForm({ onSubmit, values = {} }) {
  const classes = useStyles();
  const theme = useTheme();
  const { loginError } = useSelector((state) => state.user);

  const initialValues = {};

  const data = {
    ...initialValues,
    ...values
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required("Please enter your username"),
    password: yup.string().min(6).required("Please enter your password")
  });

  return (
    <Formik initialValues={data} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, setFieldTouched, handleSubmit, setFieldValue, dirty, errors }) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ width: "100%" }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              // helperText={`${values.locationInterest.length}/${350}`}
              variant="outlined"
              value={values.username}
              onChange={(event) => {
                setFieldValue("username", event.target.value);
              }}
              onBlur={() => {
                setFieldTouched("username", true);
              }}
              label="Username"
              placeholder="Enter username"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              variant="outlined"
              value={values.password}
              onChange={(event) => {
                setFieldValue("password", event.target.value);
              }}
              onBlur={() => {
                setFieldTouched("password", true);
              }}
              label="Password"
              placeholder="Enter password"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              type="password"
            />
          </FormControl>

          <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={!dirty || !!(dirty && errors && Object.keys(errors).length)}
              onClick={handleSubmit}
              style={{ marginTop: theme.spacing(3), width: "100%" }}
            >
              Sign In
            </Button>
          </Box>
          {loginError && (
            <Box
              style={{
                position: "relative",
                width: "100%",
                height: 35
              }}
            >
              <ErrorMessage message={loginError} />
            </Box>
          )}
        </Box>
      )}
    </Formik>
  );
}
