import api from "../api";
import { persistor } from "index";
import { APIError } from "@/errors";
import { LOCAL_STORAGE_KEYS } from "@/constants";

const INITIAL_STATE = {
  authorized: false,
  authorizing: false,
  loggingIn: false,

  error: false,
  internalUsers: null,
  loading: false,
  loginError: null,
  optingIn: false,
  optingOut: false,
  resumeSuccess: false,
  submission: null,
  // placement
  placement: null,
  submitted: false,
  submitting: false,
  token: null,
  username: null,
  // this is redundant with the root state.
  user: null,
  role: null //TODO: Check if role is being used
};

export const user = {
  state: INITIAL_STATE,

  reducers: {
    setLoggingIn(state, loggingIn) {
      return {
        ...state,
        loggingIn
      };
    },

    setLoading(state, loading) {
      return {
        ...state,
        loading
      };
    },

    update(state, updates) {
      return {
        ...state,
        ...updates
      };
    },

    setInternalUsers(state, payload) {
      return {
        ...state,
        internalUsers: payload.data
      };
    },

    setUser(state, { username, authorized, user }) {
      return {
        ...state,
        username,
        authorized,
        user
      };
    },

    setUserSubmission(state, data) {
      return {
        ...state,
        submission: data,
        loading: false
      };
    },

    setUserPlacement(state, data) {
      return {
        ...state,
        placement: data,
        loading: false
      };
    },

    setError(state, error) {
      return {
        ...state,
        loading: false,
        ...error
      };
    },

    purgeErrors(state) {
      return {
        ...state,
        error: false,
        loginError: null
      };
    },

    setSubmitting(state, payload) {
      return {
        ...state,
        submitting: payload
      };
    },

    setOpting(state, payload) {
      return {
        ...state,
        ...payload
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    // @TODO this method could be replaced with a call to setUserToken.
    async setAnonymousToken() {
      // TODO: figure out a better way to handle anonymouse API requests
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, "anonymous");
      api.defaults.headers.common["Authorization"] = "anonymous";
    },
    async setUserToken(payload) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, payload);

      if (payload) {
        api.defaults.headers.common["Authorization"] = payload;
      }
    },
    async attemptLogout(_payload, { user }) {
      const { token } = user;

      if (token) {
        api.post("/logout");
      }

      // pause and purge persisted data on logout
      persistor.pause();
      setTimeout(async () => await persistor.purge(), 200);

      // reset api instance's token
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, null);

      // purge redux models
      dispatch.app.purge();
      dispatch.calls.purge();
      dispatch.candidates.purge();
      dispatch.clients.purge();
      dispatch.jobs.purge();
      dispatch.notes.purge();
      dispatch.placements.purge();
      dispatch.reporting.purge();
      dispatch.searches.purge();
      dispatch.submissions.purge();
      dispatch.user.purge();
    },
    async logout() {
      // pause and purge persisted data on logout
      persistor.pause();
      setTimeout(async () => await persistor.purge(), 200);

      // reset api instance's token
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, null);

      // purge redux models
      dispatch.app.purge();
      dispatch.calls.purge();
      dispatch.candidates.purge();
      dispatch.clients.purge();
      dispatch.jobs.purge();
      dispatch.notes.purge();
      dispatch.placements.purge();
      dispatch.reporting.purge();
      dispatch.searches.purge();
      dispatch.submissions.purge();
      dispatch.user.purge();
    },
    async login(payload) {
      try {
        dispatch.user.setLoggingIn(true);

        dispatch.user.setError({
          error: null,
          loginError: null
        });

        const { data } = await api.post("/login", payload);

        if (!data.success && data.error) {
          throw new Error(data.error);
        }

        const { token, refresh_token, user } = data.data;

        dispatch.user.setUser({
          username: payload.username,
          authorized: true,
          user
        });

        if (token && refresh_token) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token);
          localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refresh_token);
          api.defaults.headers.common["Authorization"] = token;
        }
      } catch (loginError) {
        await dispatch.user.logout();

        dispatch.user.setError({ loginError: loginError.message });

        if (loginError instanceof APIError) {
          dispatch.app.setLastAPIError(loginError);
        }

        // throw error;
      } finally {
        dispatch.user.setLoggingIn(false);
      }
    },
    async sendResume(payload) {
      try {
        await api.post("/resume", payload);

        dispatch.user.update({
          resumeSuccess: true
        });
      } catch (error) {
        dispatch.user.update({
          resumeSuccess: false
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async fetchInternalUsers() {
      try {
        const response = await api.get("/ownerUsers");
        dispatch.user.setInternalUsers(response.data);
      } catch (error) {
        dispatch.user.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      }
    },
    async postClose(payload) {
      try {
        dispatch.user.setSubmitting(true);

        await api.post("/placementPostClose", payload);
      } catch (error) {
        dispatch.user.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.update({
          submitted: true,
          submitting: false
        });
      }
    },
    async opt(payload) {
      try {
        dispatch.user.setSubmitting(true);
        await api.post("/opt", payload);

        // TODO: move this to component level state
        dispatch.user.update({
          resumeSuccess: true
        });
      } catch (error) {
        // console.log("Error submitting submission opt in: ", error);
        dispatch.user.setError({ error });

        // TODO: move this to component level state
        dispatch.user.update({
          resumeSuccess: false
        });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.update({
          submitted: true,
          submitting: false
        });
      }
    },
    async optIn(payload) {
      try {
        dispatch.user.setSubmitting(true);
        await api.post("/submission", payload);
      } catch (error) {
        dispatch.user.setError({ error });

        if (error instanceof APIError) {
          dispatch.app.setLastAPIError(error);

          // TODO: implement model specific lastapierror
          // dispatch.notes.setLastAPIError({
          //   lastAPIError: error,
          //   lastAPIErrorEffect: "fetch",
          // });
        }

        // throw error;
      } finally {
        dispatch.user.update({
          submitted: true,
          submitting: false
        });
      }
    }
  })
};
