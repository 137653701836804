import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";
// import useScrollTrigger from "@mui/material/useScrollTrigger";
import "@fontsource/roboto";

import "./App.css";

import PrivateRoute from "@/views/Internal/PrivateRoute";
import { Home, JobList, Login, NotFound, Opt, PostClose } from "@/views/Public";
import {
  Home as InternalHomeView,
  JobLanding as InternalJobLandingView,
  Job as InternalJobView,
  Reporting as InternalReportingView,
  ReportingJob as InternalReportingJobView,
  SellingPoints as InternalSellingPointsView,
  ActiveSearchesView as InternalActiveSearchesView,
  Contracts
} from "@/views/Internal";

// function HideOnScroll(props) {
//   const { children } = props;
//   const trigger = useScrollTrigger();

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

export default function App() {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [appInitialized, setAppInitialized] = useState(false);

  useEffect(() => {
    if (!appInitialized) {
      dispatch.app.setResponsiveDevice(isTabletOrMobile);
      setAppInitialized(true);
    }
  }, [appInitialized, dispatch, isTabletOrMobile]);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/jobs" component={JobList} />
        <Route path="/404" component={NotFound} />
        <Route path={["/opt/:id", "/opt"]} component={Opt} />
        <Route path={["/feedback/:id", "/feedback"]} component={PostClose} />
        <Route exact path="/" component={Home} />

        <Route
          path="/variner"
          render={({ match: { url } }) => (
            <>
              <PrivateRoute exact path={`${url}/contracts`}>
                <Contracts />
              </PrivateRoute>
              <PrivateRoute exact path={`${url}/home`}>
                <InternalHomeView />
              </PrivateRoute>
              <PrivateRoute exact path={`${url}/job`}>
                <InternalJobLandingView />
              </PrivateRoute>
              <PrivateRoute path={`${url}/job/:id`}>
                <InternalJobView />
              </PrivateRoute>
              <PrivateRoute exact path={`${url}/reporting`}>
                <InternalReportingView />
              </PrivateRoute>
              <PrivateRoute path={`${url}/reporting/:id`}>
                <InternalReportingJobView />
              </PrivateRoute>
              <PrivateRoute path={`${url}/sellingpoints/:id`}>
                <InternalSellingPointsView />
              </PrivateRoute>
              <PrivateRoute path={`${url}/active-searches`}>
                <InternalActiveSearchesView />
              </PrivateRoute>
            </>
          )}
        />
      </Switch>
      <ToastContainer />
    </>
  );
}
