import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "@fontsource/roboto";

import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BarChartIcon from "@mui/icons-material/BarChart";
import WorkIcon from "@mui/icons-material/Work";
import GavelIcon from "@mui/icons-material/Gavel";
import {
  Box,
  Button,
  Popover,
  Typography,
  Avatar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import { prettyName } from "@/helpers/user";
import logo from "@/assets/logo.png";
import { colors, constants } from "@/styles/config";
import { useAuth } from "@/hooks/use-auth.hook";
import { isAdminSelector } from "models/selectors/user";

const useStyles = makeStyles((theme) => ({
  logo: {},
  menuToggle: {
    display: "flex",
    alignItems: "center",
    gap: ".5vw",
    marginLeft: "5px",
    marginTop: "10px",
    marginBottom: "20px"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.darkGray,
    transition: theme.transitions.create(["width", "margin"], {
      easing: "sharp",
      duration: "0.3s"
    })
  },
  hide: {
    display: "none"
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    backgroundColor: colors.darkGray,
    transition: theme.transitions.create("width", {
      easing: "sharp",
      duration: "0.3s"
    })
  },
  drawerClose: {
    backgroundColor: colors.darkGray,
    transition: theme.transitions.create("width", {
      easing: "sharp",
      duration: "0.3s"
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0
    },
    borderRight: "1px solid rgba(255, 255, 255, 0.2)"
  },
  menuTextRoot: {
    whiteSpace: "normal"
  },
  menuText: {
    fontSize: 12,
    color: "rgba(255, 255, 255, 0.8)",
    marginLeft: 10
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(5, 1.5)
    // necessary for content to be below app bar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(7.5),
    paddingRight: 0,
    paddingLeft: 0
  },
  formControl: {
    margin: 0,
    borderRadius: 3,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(8),
    backgroundColor: "rgba(238, 238, 238, 0.1)",
    fontSize: 12,
    color: colors.white
  },
  logOut: {
    display: "flex",
    alignItems: "center",
    gap: ".5vw",
    marginLeft: "10px"
  }
}));

export default function SideNavDrawer() {
  const [anchorEl, setAnchorEl] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(600);

  const { user } = useSelector((state) => state.user);
  const isAdmin = useSelector(isAdminSelector);

  const classes = useStyles();
  const theme = useTheme();
  let history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    if (menuOpen === true) {
      setDrawerWidth(600);
    } else setDrawerWidth(200);
  }, [menuOpen]);

  const handleLoggedClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoggedClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    auth.signout(() => history.push("/login"));
  };

  const handleDrawerClick = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    } else setMenuOpen(false);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const menuItems = useMemo(() => {
    const defaultItems = [
      { label: "Home", link: "/variner/home", icon: HomeIcon },
      {
        label: "Reporting Dashboard",
        link: "/variner/reporting",
        icon: BarChartIcon
      },
      {
        label: "Active Job Searches",
        link: "/variner/active-searches",
        icon: WorkIcon
      }
    ];

    const adminItems = [
      {
        label: "Contracts",
        link: "/variner/contracts",
        icon: GavelIcon
      }
    ];

    return isAdmin ? [...defaultItems, ...adminItems] : defaultItems;
  }, [isAdmin]);

  return (
    <Box>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        open={menuOpen}
        classes={{
          paper: classes.drawerOpen
        }}
        sx={{ minWidth: drawerWidth }}
      >
        <Box className={classes.menuToggle}>
          <IconButton
            onClick={handleDrawerClick}
            disableRipple={true}
            color="secondary"
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          {menuOpen && (
            <img
              className={classes.logo}
              src={logo}
              style={{ maxHeight: 20, marginRight: "30px" }}
              alt="logo"
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: "calc(100vh - 64px)" }}
        >
          <List>
            {menuItems.map((item) => {
              const Icon = item.icon;
              return (
                <ListItem
                  button
                  disabled={item.disabled}
                  key={item.label}
                  onClick={() => {
                    if (history.location.pathname !== item.link) {
                      history.push(item.link);
                      handleDrawerClose();
                    }
                  }}
                >
                  <ListItemIcon style={{ color: colors.white, minWidth: 20 }}>
                    <Icon />
                  </ListItemIcon>
                  {menuOpen && (
                    <ListItemText
                      classes={{
                        root: classes.menuTextRoot,
                        primary: classes.menuText
                      }}
                      primary={item.label}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
          <List>
            <ListItem button disableRipple={true}>
              <ListItemIcon style={{ minWidth: 12 }}>
                <Avatar
                  onClick={handleLoggedClick}
                  style={{
                    width: 36,
                    height: 36,
                    marginLeft: "-4px",
                    cursor: "pointer",
                    backgroundColor: constants.colorsForData[prettyName(user)]
                  }}
                >
                  <Typography variant="h5">
                    {`${user.firstName.charAt(0).toUpperCase()}${user.lastName
                      .charAt(0)
                      .toUpperCase()}`}
                  </Typography>
                </Avatar>
              </ListItemIcon>

              {menuOpen && (
                <Box className={classes.logOut}>
                  <ListItemText
                    onClick={handleLoggedClick}
                    classes={{ primary: classes.menuText }}
                    primary={"Log out"}
                  />
                  <ExpandMoreIcon sx={{ color: "white" }} />
                </Box>
              )}
              <Popover
                style={{ marginTop: theme.spacing(1) }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleLoggedClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                <Box p={0.5}>
                  <Button
                    onClick={handleLogoutClick}
                    style={{ minWidth: 150 }}
                    startIcon={<ExitToAppIcon />}
                  >
                    Sign out
                  </Button>
                </Box>
              </Popover>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
