import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getContracts } from "@/services";
import { ContractsTable } from "./components/ContractsTable";
import { UTILS } from "./utils";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import { BASE_URL, CONTRACTS_CSV_URL } from "@/constants";
import { useSelector } from "react-redux";
import { userSelector, isAdminSelector } from "models/selectors/user";

const FILE_NAME = "contracts.csv";

const Contracts = () => {
  let history = useHistory();

  const { user } = useSelector(userSelector);
  const isAdmin = useSelector(isAdminSelector);

  const [csv, setCsv] = useState();
  const [contracts, setContracts] = useState();
  const [copied, setCopied] = useState(false);

  const handleCloseSnackbar = () => {
    setCopied(false);
  };

  useEffect(() => {
    getContracts().then((contracts) => {
      setContracts(contracts);
    });
  }, []);
  const authHash = user?.auth_hash || "";
  const url = `${BASE_URL}${CONTRACTS_CSV_URL}?auth_hash=${authHash}`;

  useEffect(() => {
    const csv = UTILS.handleExport(contracts?.data?.data);
    setCsv(csv);
  }, [contracts]);

  const handleCopy = async (textToCopy) => {
    await navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };

  // @TODO [salva@16-12-2024] [#VA2-102] this should be a separate view.
  const PermissionErrorScreen = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <p>You do not have permission to access this page.</p>

      <Button onClick={() => history.push("/variner/home")}>Navigate To Home</Button>
    </div>
  );

  return !isAdmin ? (
    <PermissionErrorScreen />
  ) : (
    <div style={{ marginLeft: "64px", padding: "24px" }}>
      <h2>Contracts</h2>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button variant="contained" onClick={() => handleCopy(url)}>
          Generate CSV URL
        </Button>

        {csv && (
          <Button variant="contained" component="a" href={csv} download={FILE_NAME}>
            Download CSV
          </Button>
        )}
      </Box>
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          ¡URL copied to clipboard!
        </Alert>
      </Snackbar>

      {contracts?.data?.data && <ContractsTable contracts={contracts.data.data} />}
    </div>
  );
};

export { Contracts };
