import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "@/constants";

// Create axios instance.
const client = axios.create();

// eslint-disable-next-line no-undef
const API_URL = process && process?.env.REACT_APP_API_URL;

const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

client.defaults.baseURL = `${API_URL}/v2`;
client.defaults.headers.common["Authorization"] = accessToken;

export { client };
