import moment from "moment";

const INITIAL_STATE = {
  loading: false,
  error: false,
  bootstrapped: false, // What is this for?
  rightDrawer: false,
  drawerComponent: null,
  isMobile: false,
  presentationMode: false,
  currentPageTitle: null,
  dateRange: {
    startDate: moment().clone().startOf("isoWeek"),
    endDate: moment().clone().endOf("isoWeek")
  },
  customDateRange: null,
  rangeSelection: "thisWeek"
};

export const app = {
  state: INITIAL_STATE,
  reducers: {
    update(state, updates) {
      return {
        ...state,
        ...updates
      };
    },

    setLoading(state, loading) {
      return {
        ...state,
        loading
      };
    },

    setPresentationMode(state, payload) {
      return {
        ...state,
        presentationMode: payload
      };
    },

    setCurrentPageTitle(state, payload) {
      return {
        ...state,
        currentPageTitle: payload
      };
    },

    toggleDrawer(state, payload) {
      return {
        ...state,
        rightDrawer: payload.open,
        drawerComponent: payload.component
      };
    },

    setResponsiveDevice(state, payload) {
      return {
        ...state,
        isMobile: payload
      };
    },

    setDateRange(state, payload) {
      return {
        ...state,
        dateRange: payload
      };
    },

    setCustomDateRange(state, payload) {
      return {
        ...state,
        customDateRange: payload
      };
    },

    setRangeSelection(state, payload) {
      return {
        ...state,
        rangeSelection: payload
      };
    },

    setLastAPIError(state, lastAPIError) {
      return {
        ...state,
        lastAPIError
      };
    },

    clearLastAPIError(state) {
      return {
        ...state,
        lastAPIError: null,
        lastAPIErrorEffect: null
      };
    },

    purge() {
      return {
        ...INITIAL_STATE
      };
    }
  },
  effects: (dispatch) => ({
    async bootstrap(_, { user, app }) {
      const { authorized } = user;
      const { bootstrapped } = app;
      if (bootstrapped || !authorized) return;

      // TODO: implement persistor stuff
      // persistor.pause();
      // await persistor.flush();
      // persistor.persist();

      // TODO: do we still need this?
      // dispatch.jobs.resetJobStatePartial();

      dispatch.app.update({
        loading: false,
        bootstrapped: true
      });

      return true;
    }
  })
};
