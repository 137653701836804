import React, { useCallback, useEffect, useState, useMemo } from "react";

// 3rd party libraries
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  Box,
  Backdrop,
  Typography,
  Container,
  CircularProgress,
  Drawer,
  Switch,
  Button,
  Grid
} from "@mui/material";

// styles
import { colors } from "@/styles/config";

// components
import CandidateDrawer from "@/components/CandidateDrawer";
import JobSlateHeader from "@/components/JobSlateHeader";
import CandidateSlateTable from "@/components/tables/CandidateSlateTable";
import makeData from "@/components/tables/makeData";
import { sortSubmissionsByStatus, statuses } from "@/helpers/statuses";
import Splash from "@/components/Splash";
import { constants as styleConstants } from "@/styles/config";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AntSwitch = withStyles(() => ({
  root: {
    width: 45,
    height: 31
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  }
}))(Switch);

const useStyles = makeStyles(() => ({
  container: {
    padding: styleConstants.desktopDrawerClosedWidth / 2,
    maxWidth: "100vw",
    minHeight: "100vh",
    // overflow: "hidden",
    backgroundColor: "white"
  },
  content: {
    paddingLeft: styleConstants.desktopDrawerClosedWidth / 2
  },
  boxContainer: {
    maxWidth: "100%",
    marginTop: 200,
    zIndex: 15000,
    backgroundColor: "white"
  }
}));

const tableGroups = [
  {
    label: "Variner Pursuing",
    statuses: Object.keys(statuses)
      .map((key) => (statuses[key].group === "Variner Pursuing" ? statuses[key].bhLabel : null))
      .filter((s) => s)
  },
  {
    label: "New Candidates",
    statuses: ["Client Submission"]
  },
  {
    label: "In Process",
    statuses: Object.keys(statuses)
      .map((key) =>
        statuses[key].group === "In Process" && statuses[key].bhLabel !== "Client Submission"
          ? statuses[key].bhLabel
          : null
      )
      .filter((s) => s)
  },
  {
    label: "Reviewed",
    statuses: Object.keys(statuses)
      .map((key) => (statuses[key].group === "Rejected" ? statuses[key].bhLabel : null))
      .filter((s) => s)
  }
];

const getGroupedSubmissions = (submissions) => {
  return {
    "In Process": submissions.filter((submission) => {
      const groupStatuses = tableGroups.find((group) => group.label === "In Process").statuses;
      // console.log({ groupStatuses });
      return groupStatuses.indexOf(submission.status) > -1;
    }),
    "New Candidates": submissions.filter((submission) => {
      const groupStatuses = tableGroups.find((group) => group.label === "New Candidates").statuses;

      return groupStatuses.indexOf(submission.status) > -1;
    }),
    "Variner Pursuing": submissions.filter((submission) => {
      const groupStatuses = tableGroups.find(
        (group) => group.label === "Variner Pursuing"
      ).statuses;
      return groupStatuses.indexOf(submission.status) > -1;
    }),
    Reviewed: submissions.filter((submission) => {
      const groupStatuses = tableGroups.find((group) => group.label === "Reviewed").statuses;
      return groupStatuses.indexOf(submission.status) > -1;
    })
  };
};

export default function InternalJobView() {
  // ↓ styling ↓
  const theme = useTheme();
  const classes = useStyles();

  // ↓ redux ↓
  const dispatch = useDispatch();

  const { loading, submissionsLoading, generatingResume, submissions, currentJob } = useSelector(
    (state) => state.jobs
  );

  const { searchesSubmissions } = useSelector((state) => state.searches);

  const { presentationMode, appLoading } = useSelector((state) => state.app);

  // ↓ other hooks ↓
  const { id } = useParams();
  let history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  // ↓ state ↓
  const [jobInitialized, setJobInitialized] = useState(false);
  const [groupedSubmissions, setGroupedSubmissions] = useState();
  const [sortedSubmissions, setSortedSubmissions] = useState(submissions);
  const [tableData, setTableData] = useState(null);
  const [skipPageReset] = useState(false);

  const [activeStatusGroup, setActiveStatusGroup] = useState("New Candidates");

  const [selectedStatuses, setSelectedStatuses] = useState(["Client Submission"]);

  const [candidateDrawer, setCandidateDrawer] = useState({
    open: false
  });

  // ↓ computed ↓

  const submissionId = query.get("submission");

  const submissionsFormattedToDrawer = useMemo(
    () => Object.values(searchesSubmissions || {}).flat(),
    [searchesSubmissions]
  );

  // ↓ callbacks ↓

  const toggleDrawer = useCallback(
    ({ open, submission, component }, event) => {
      if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
        return;
      }

      if (!open) {
        history.replace(`/variner/job/${id}`);
      }

      if (submission) {
        dispatch.jobs.setCurrentSubmission(submission);

        setCandidateDrawer({
          open,
          component: <CandidateDrawer />
        });
      } else {
        setCandidateDrawer({
          open,
          component
        });
      }
    },
    [history, dispatch, id]
  );

  // ↓ effects ↓

  useEffect(() => {
    dispatch.app.setCurrentPageTitle("Candidate Slate");
    return function cleanup() {
      dispatch.app.setCurrentPageTitle(null);
    };
  }, [dispatch]);

  useEffect(() => {
    const statusOptions = Object.keys(statuses).map((key) => statuses[key].bhLabel);

    if (id && (!jobInitialized || (jobInitialized && jobInitialized !== id))) {
      // console.log("bootstrapping data for job # ", id);

      dispatch.jobs
        .startupJob({
          id,
          dashboard: true,
          status: statusOptions
        })
        .then(() => setJobInitialized(id));
    }
  }, [id, jobInitialized, dispatch]);

  useEffect(() => {
    if (submissionId && submissions && submissions.length) {
      const submissionById = submissionsFormattedToDrawer.find(
        (submission) => submission.id === parseInt(submissionId)
      );

      if (submissionById) {
        toggleDrawer({ open: true, submission: submissionById });
      } else {
        history.replace(`/variner/job/${id}`);
      }
    }
  }, [submissionId, submissions, history, id, toggleDrawer, submissionsFormattedToDrawer]);

  useEffect(() => {
    if (submissions && submissions.length >= 0) {
      const grouped = getGroupedSubmissions(submissions);
      setGroupedSubmissions(grouped);

      const madeTableData = makeData(submissions);
      setTableData(madeTableData);

      let sortedSubmissions =
        submissions.length &&
        submissions.sort((a, b) => {
          const aName = `${a.candidate.lastName} ${a.candidate.firstName}`;
          const bName = `${b.candidate.lastName} ${b.candidate.firstName}`;

          return aName <= bName ? -1 : 1;
        });

      sortedSubmissions = sortSubmissionsByStatus(sortedSubmissions);
      setSortedSubmissions(sortedSubmissions);
    }
  }, [submissions]);

  const handleClickTableRow = (submissionId, event) => {
    const activeSubmissionToUse = submissionsFormattedToDrawer?.find(
      (submission) => submission.id === submissionId
    );

    toggleDrawer(
      {
        open: true,
        submission: activeSubmissionToUse
      },
      event
    );
  };

  // ↓ renders ↓

  if (appLoading || loading || !currentJob) {
    return <Splash />;
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box className={classes.boxContainer}>
        <Backdrop
          open={generatingResume}
          style={{
            color: colors.white,
            zIndex: 2000,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography variant="h1" style={{ color: colors.white }}>
              Generating Candidate Slate
            </Typography>
            <Typography variant="h3" style={{ marginTop: 5, color: colors.white }}>
              This can take a min or two.
            </Typography>
          </Box>

          <CircularProgress color="inherit" style={{ marginTop: theme.spacing(2) }} />
        </Backdrop>

        <Drawer
          anchor="right"
          open={candidateDrawer.open}
          onClose={(event) => toggleDrawer({ open: false }, event)}
        >
          {candidateDrawer.component && candidateDrawer.component}
        </Drawer>

        <Box className={classes.content}>
          <JobSlateHeader
            {...{ groupedSubmissions }}
            style={{ left: styleConstants.desktopDrawerClosedWidth }}
          />
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={5}
            sx={{ pl: "32px" }}
          >
            <Grid item>
              <Button
                color="primary"
                onClick={() => {
                  history?.length > 1 ? history.goBack() : window.close();
                }}
                startIcon={<ArrowBackIosIcon style={{ width: 12 }} />}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                {`${currentJob.clientCorporation?.name} | ${currentJob.customText3}`}
              </Typography>
            </Grid>
          </Grid>

          <Container className={classes.container}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" mb={0.5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography variant="body2" style={{ color: colors.gray43, fontSize: 10 }}>
                  Presenting
                </Typography>
                <AntSwitch
                  checked={presentationMode}
                  onChange={() => dispatch.app.setPresentationMode(!presentationMode)}
                  color="primary"
                  name="presentationMode"
                  inputProps={{ "aria-label": "presentationMode" }}
                />
              </Box>
            </Box>

            <Box>
              {submissionsLoading && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  style={{ paddingTop: 50 }}
                >
                  <CircularProgress size={50} />
                </Box>
              )}
              {!submissionsLoading && submissions && !!(submissions.length === 0) && (
                <Box py={8}>
                  <Typography variant="body1">
                    No candidate submissions for this status at this time.
                  </Typography>
                </Box>
              )}

              {!submissionsLoading && !!tableData && !!(tableData.length >= 0) && (
                <CandidateSlateTable
                  presentationMode={presentationMode}
                  activeStatusGroup={activeStatusGroup}
                  setActiveStatusGroup={setActiveStatusGroup}
                  data={tableData}
                  tableGroups={tableGroups}
                  selectedStatuses={selectedStatuses}
                  setSelectedStatuses={setSelectedStatuses}
                  sortedSubmissions={sortedSubmissions}
                  groupedSubmissions={groupedSubmissions}
                  skipPageReset={skipPageReset}
                  initialPageSize={100}
                  toggleDrawer={toggleDrawer}
                  onOpenClick={handleClickTableRow}
                />
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </Container>
  );
}
