import { INTERCEPTORS_BY_HTTP_STATUS_CODE } from "./interceptorsByHttpStatusCode";

export const setupAuthInterceptor = (client, hasActiveRequests) => {
  client.interceptors.response.use(undefined, async (error) => {
    const authInterceptor = INTERCEPTORS_BY_HTTP_STATUS_CODE[error.response.status];

    if (typeof authInterceptor === "function") {
      authInterceptor(client, hasActiveRequests);
    } else {
      // log errors without interceptor.
      console.error({ error });
    }

    return error;
  });
};
