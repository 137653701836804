import React from "react";

// 3rd party libraries
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Box, AppBar, Toolbar, Typography } from "@mui/material";

// assets
import logo from "@/assets/logo.png";

// styles
import { colors, constants } from "@/styles/config";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginBottom: 10
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colors.darkGray
  }
}));

export default function Home() {
  const classes = useStyles();
  // @TODO [04-12-2024@salva] [#VA2-107] remove redirect logic for guess home.
  const { authorized, authorizing } = useSelector((state) => state.user);
  const { isMobile } = useSelector((state) => state.app);

  if (authorized && !authorizing) return <Redirect to="/variner/home" />;

  if (!authorized && !authorizing) return <Redirect to="/login" />;

  return (
    <Box style={{ height: "100vh", width: "100vw" }}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width={isMobile ? "100%" : "50%"}
            style={{
              padding: isMobile ? constants.defaultMobilePadding / 2 : 16,
              paddingLeft: 72
            }}
          >
            <img
              className={classes.logo}
              src={logo}
              style={{ maxHeight: isMobile ? 15 : 25 }}
              alt="logo"
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box style={{ width: "40%" }}>
          <Typography style={{ color: colors.gray43, marginBottom: 16 }}>Thank you</Typography>
        </Box>
      </Box>
    </Box>
  );
}
