import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SideNavDrawer from "@/components/SideNavDrawer";
import Splash from "@/components/Splash";
import { userSelector } from "models/selectors/user";

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,

    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: "#f8f8f8"
  }
}));

// eslint-disable-next-line react/prop-types
export default function BoostrapWrapper({ children }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [filteredActiveJobs, setFilteredActiveJobs] = useState(null);

  const { activeJobs } = useSelector((state) => state.jobs);

  const { user, internalUsers } = useSelector(userSelector);

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        if (!activeJobs) {
          await dispatch.jobs.fetchActiveBullhornJobs();
        }

        if (!internalUsers) {
          await dispatch.user.fetchInternalUsers();
        }
      } catch (error) {
        throw error;
      }
    };

    if (user) {
      setTimeout(() => {
        fetchData();
      }, 500);
    }
  }, [dispatch, user, activeJobs, internalUsers]);

  useEffect(() => {
    if (!filteredActiveJobs && activeJobs) setFilteredActiveJobs(activeJobs);
  }, [activeJobs, filteredActiveJobs, setFilteredActiveJobs]);

  // @TODO review this loading.
  if (!user) return <Splash />;

  return (
    <Box>
      <SideNavDrawer />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}
