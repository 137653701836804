export const setupRequestCounterInterceptor = (client) => {
  let activeRequests = 0;

  // Interceptor to increase the request counter in each request.
  client.interceptors.request.use((config) => {
    activeRequests++;
    return config;
  });

  // Interceptor to decrease the request counter after each request.
  client.interceptors.response.use(
    (response) => {
      activeRequests--;
      return response;
    },
    (error) => {
      activeRequests--;
      return Promise.reject(error);
    }
  );

  // count active requests.
  const hasActiveRequests = () => activeRequests > 0;

  return { hasActiveRequests };
};
